import { Box, colors } from "@biblioteksentralen/js-utils";
import { imageUrlBuilder } from "@libry-content/common";
import { DecorativeImage, ImageWithMetadata } from "@libry-content/types";
import NextImage from "next/legacy/image";
import { ComponentProps } from "react";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { GalleryImage } from "./GalleryImage";
import { safelyGetImageDimensions } from "./safelyGetImageDimensions";

export type SanityImageSrc = ImageWithMetadata | DecorativeImage | GalleryImage;

export type SanityImageCoreProps = {
  image?: SanityImageSrc;
  resolution: number;
  aspectRatio: number | "fill" | "original";
  quality?: number;
  customNextImageProps?: Partial<React.ComponentProps<typeof NextImage>>;
};

export const SanityImageCore = (props: SanityImageCoreProps) => {
  const nextImageProps = useNextImageProps(props);

  if (!nextImageProps) {
    console.error("Kunne ikke bygge url for bilde", props);
    return (
      <Placeholder
        aspectRatio={
          typeof props.aspectRatio !== "number" ? safelyGetImageDimensions(props.image).aspectRatio : props.aspectRatio
        }
      />
    );
  }

  return (
    <Box
      // speed of blur-fade
      sx={{ img: { transition: "filter 0.075s" } }}
    >
      <NextImage {...nextImageProps} />
    </Box>
  );
};

const useNextImageProps = (props: SanityImageCoreProps): ComponentProps<typeof NextImage> | undefined => {
  const { ts } = useTranslation();

  const alt = (isImageWithMetadata(props.image) && props.image.altText?.enabled && ts(props.image.altText?.text)) || "";

  const commonProps: Partial<ComponentProps<typeof NextImage>> = {
    alt,
    placeholder: "blur",
    ...props.customNextImageProps,
  };

  const urlBuilder = imageUrlBuilder(props.image)
    ?.quality(props.quality ?? 85)
    .format("webp");

  if (props.aspectRatio === "fill") {
    const src = urlBuilder?.width(props.resolution).url();
    const blurDataURL = urlBuilder?.width(10).url();

    if (!src) return undefined;

    const hotspotCorrection = {
      x: Math.round((props.image?.hotspot?.x ?? 0.5) * 100),
      y: Math.round((props.image?.hotspot?.y ?? 0.5) * 100),
    };

    return {
      src,
      blurDataURL,
      layout: "fill",
      objectFit: "cover",
      objectPosition: `${hotspotCorrection.x}% ${hotspotCorrection.y}%`,
      ...commonProps,
    };
  }

  if (props.aspectRatio === "original") {
    const { width, height } = safelyGetImageDimensions(props.image);
    const src = urlBuilder?.width(props.resolution).url();
    const blurDataURL = urlBuilder?.width(10).url();

    if (!src) return undefined;

    return {
      src,
      blurDataURL,
      width,
      height,
      ...commonProps,
    };
  }

  const width = props.resolution;
  const height = Math.round(width / props.aspectRatio);
  const src = urlBuilder?.size(width, height).url();
  const blurDataURL = urlBuilder?.size(10, Math.round(10 / props.aspectRatio)).url();

  if (!src) return undefined;

  return {
    width,
    height,
    src,
    blurDataURL,
    ...commonProps,
  };
};

const isImageWithMetadata = (image: SanityImageCoreProps["image"]): image is ImageWithMetadata => {
  return image?._type === "imageWithMetadata";
};

const Placeholder = (props: { aspectRatio: number }) => (
  <Box
    width="100%"
    background={`linear-gradient(-35deg, ${colors.grey15}, ${colors.grey05})`}
    aspectRatio={props.aspectRatio}
  />
);
